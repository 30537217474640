import PartnerCard from "./PartnerCard";
import classes from "./PartnersSection.module.css";

const PartnersSection = () => {
  return (
    <>
      <div className={`container ${classes.PartnersSection}`}>
        <h2 className={classes.sectionTitle}>partners</h2>
        <div className={`row ${classes.cardsContainer}`}>
          <PartnerCard name="Edrak"
            desc="Medical student initiative that encourages writing research"
            image={require("../images/edrak.png")}
            message="Our collaboration with Researchers Hub is a source of great satisfaction for us, and we aspire to achieve our vision of creating an environment rich with opportunities." />
          <PartnerCard
            name="Scientific Research"
            desc="Our initiative aimed at empowering students and helping them acquire skills in scientific research and innovation."
            image={require("../images/scientific.jpg")}
            message="Through our collaboration with ResearchersHub we are committed to fostering a culture of research excellence and innovation, providing students with the tools and support they need to excel in their Scientific interests. This partnership marks a significant step towards empowering the next generation of researchers and innovators." />
        </div>
      </div>
    </>
  );
};

export default PartnersSection;
