import { useEffect, useState } from "react";
import PrimaryActionButton from "./PrimaryActionButton";
import classes from "./RequestItem.module.css";
import SingleCategoryItem from "./SingleCategoryItem";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { useSubmit, useActionData, useNavigation } from "react-router-dom";

const RequestItem = ({
  userId,
  groupId,
  groupName,
  name,
  year,
  university,
  major,
  interests,
  isExperienced,
  willingToLead,
  haveAnIdea,
  haveAnSupervisor,
  setJoinRequests,
}) => {
  const [action, setAction] = useState(null);
  const actionData = useActionData();
  const navigation = useNavigation();
  const submit = useSubmit();

  const handleAcceptOrDecline = (action) => {
    setAction(action);

    const data = {
      participantId: userId,
      groupId,
      action,
    };

    submit(
      {
        intent: "handle-join",
        ...data,
      },
      { method: "post", action: `/group/${groupId}/${groupName}` }
    );
  };

  useEffect(() => {
    if (actionData && actionData.intent === "handle-join") {
      if (actionData.status === "ok") {
        toast.success(actionData?.response?.data?.msg);

        // removes the current request from the joinRequests list
        setJoinRequests((prevJoinRequests) =>
          prevJoinRequests.filter((joinRequest) => joinRequest.id !== userId)
        );
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            "An error occurred. Please try again later."
        );
      }
      setAction(null);
    }
  }, [actionData]);

  return (
    <div className={`${classes.requestContainer}`}>
      <div className="row">
        <div className="col-lg col-12">
          <p className={classes.userName}>{name}</p>
          <p
            className={classes.metaData}
          >{`${year != "None" ? year + " | " : ""} ${university != "None" ? university + " | " : ""}  ${major != "None" ? major + " | " : ""} interested in ${interests
            .map((interest) => interest.label)
            .join(", ")}`}</p>
          <div className="row g-2">
            {isExperienced && (
              <div className="col-auto">
                <SingleCategoryItem category="experienced" />
              </div>
            )}
            {willingToLead && (
              <div className="col-auto">
                <SingleCategoryItem category="willing to lead" />
              </div>
            )}
            {haveAnIdea && (
              <div className="col-auto">
                <SingleCategoryItem category="have an idea" />
              </div>
            )}
            {haveAnSupervisor && (
              <div className="col-auto">
                <SingleCategoryItem category="have a supervisor" />
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-auto col-12 d-flex align-items-center">
          <div className="d-lg-block d-md-flex d-block justify-content-between w-100">
            <div>
              <PrimaryActionButton
                className={`${classes.actionButton} ${classes.acceptButton}`}
                buttonText={
                  navigation.state === "idle" ? (
                    "accept ✅"
                  ) : action === "accept" ? (
                    <BeatLoader color="white" loading size={10} />
                  ) : (
                    "accept ✅"
                  )
                }
                onClick={() => handleAcceptOrDecline("accept")}
              />
            </div>
            <div>
              <PrimaryActionButton
                className={`${classes.actionButton} ${classes.declineButton}`}
                buttonText={
                  navigation.state === "idle" ? (
                    "decline ❌"
                  ) : action === "decline" ? (
                    <BeatLoader color="white" loading size={10} />
                  ) : (
                    "decline ❌"
                  )
                }
                onClick={() => handleAcceptOrDecline("decline")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestItem;
