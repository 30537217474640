import { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import classes from "./SelectInterestsField.module.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectInterestsField({
  allInterests,
  interests,
  setInterests,
  required = true,
  interestsError: error,
  setInterestsError,
  interestsErrorMessage,
  setInterestsErrorMessage,
}) {
  const handleChange = (event, newValue) => {
    const {
      target: { value },
    } = event;

    setInterests(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    if (value.length === 0) {
      setInterestsError(true);
      setInterestsErrorMessage("This field is required");
    } else {
      if (parseInt(value.length) > 3) {
        setInterestsError(true);
        setInterestsErrorMessage("You can select only 3 interests at most");
      } else {
        setInterestsError(false);
      }
    }
  };

  return (
    <div className={classes.selectContainerParent}>
      <label className={classes.label}>
        Interests
        {required && <span style={{ color: "red" }}>*</span>}
        {error && (
          <span className={classes.errorMessage}>{interestsErrorMessage}</span>
        )}
      </label>
      <FormControl
        sx={{ m: 1, width: 300 }}
        className={classes.selectContainer}
      >
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={interests}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Placeholder</em>;
            }

            return selected.map((interest) => interest.key).join(", ");
          }}
          MenuProps={MenuProps}
          className={classes.selectField}
        >
          {allInterests.map((interest, key) => (
            <MenuItem key={key} value={interest}>
              <Checkbox checked={interests.indexOf(interest) > -1} />
              <ListItemText primary={interest.key} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
