import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import Modal from "react-bootstrap/Modal";
import classes from "./CreateGroupModal.module.css";
import PrimaryActionButton from "./PrimaryActionButton";
import { toast } from "react-toastify";
import FormikSelectInput from "./FormikSelectInput";
import { BeatLoader } from "react-spinners";
import useHttp from "../custom-hooks/useHttp";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

export default function GroupJoinModal({ group, ...props }) {
  const navigate = useNavigate();
  const { sendRequestAndTrackProgress, actionData, isLoading } = useHttp();

  const onSubmit = (values) => {
    const data = {
      groupId: group.id,
      info: {
        supervisor: values.supervisor,
        leader: values.leader,
        idea: values.idea,
      },
    };

    sendRequestAndTrackProgress("post", "/join/", data);
  };

  const validationSchema = Yup.object().shape({
    supervisor: Yup.string().required("This field is required"),
    leader: Yup.string().required("This field is required"),
    idea: Yup.string().required("This field is required"),
  });

  //   scroll the page to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // perform necessary actions once a response is received for the create-group API request
  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        props.onHide();
        toast.success(actionData.response?.data?.msg || "Joined successfully");
        navigate("/");
        scrollToTop();
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            "An error occurred. Please try again later."
        );
      }
    }
  }, [actionData]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={classes.customModalBody}>
        <div className={classes.modalCloseButtonContainer}>
          <span
            className={`material-symbols-outlined ${classes.modalCloseButton}`}
            onClick={() => props.onHide()}
          >
            close
          </span>
        </div>
        <div className={classes.modalBodyContainer}>
          <h3 className={classes.title}>Join Group</h3>
          <Formik
            initialValues={{ supervisor: "", leader: "", idea: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className={classes.formRow}>
                <FormikSelectInput
                  options={[
                    { value: true, key: "Yes" },
                    { value: false, key: "No" },
                  ]}
                  defaultValue="Select"
                  name="supervisor"
                  label="Do you already have a supervisor"
                  required={true}
                />
              </div>
              <div className={classes.formRow}>
                <FormikSelectInput
                  options={[
                    { value: true, key: "Yes" },
                    { value: false, key: "No" },
                  ]}
                  defaultValue="Select"
                  name="leader"
                  label="Are you willing to be the leader"
                  required={true}
                />
              </div>
              <div className={classes.formRow}>
                <FormikSelectInput
                  options={[
                    { value: true, key: "Yes" },
                    { value: false, key: "No" },
                  ]}
                  defaultValue="Select"
                  name="idea"
                  label="Do you already have a research idea?"
                  required={true}
                />
              </div>
              <PrimaryActionButton
                type="submit"
                className={classes.submitBtn}
                disabled={isLoading ? true : false}
                style={{
                  cursor: isLoading ? "not-allowed" : "pointer",
                }}
                buttonText={
                  isLoading ? (
                    <BeatLoader color="white" loading size={10} />
                  ) : (
                    <p className={classes.submitBtnText}>Join Group</p>
                  )
                }
              />
            </Form>
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
