import { Link } from "react-router-dom";
import { useRef, useContext } from "react";
import classes from "./NonLoggedInNavigationBar.module.css";
import NavBarBusinessLogo from "./NavBarBusinessLogo";
import { loginContext, signupContext } from "../App";
import PrimaryActionButton from "./PrimaryActionButton";

const NonLoggedInNavigationBar = () => {
  const menuButtonRef = useRef();
  const { setShowLoginModal } = useContext(loginContext);
  const { setShowSignupModal } = useContext(signupContext);

  return (
    <>
      <nav className={`navbar navbar-expand-md ${classes.navbarContainer}`}>
        <div className="container">
          <div>
            <Link className="businessLogo" to="/">
              <NavBarBusinessLogo />
            </Link>
          </div>
          <button
            style={{
              boxShadow: "none",
              outline: "none",
              border: "none",
            }}
            ref={menuButtonRef}
            className={`navbar-toggler m-0`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className={`material-symbols-outlined ${classes.menuIcon}`}>
              menu
            </span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarTogglerDemo01"
          >
            <div className="d-flex justify-content-lg-start justify-content-center ">
              <ul className="navbar-nav me-lg-auto me-0 mb-2 mb-lg-0">
                <li
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  className={`nav-item ${classes.customNavItem}`}
                >
                  <div onClick={() => setShowLoginModal(true)}>
                    <PrimaryActionButton
                      className={classes.loginButton}
                      buttonText="Login"
                    />
                  </div>
                </li>
                <li
                  className={`nav-item ${classes.customNavItem}`}
                  style={{ paddingRight: "0" }}
                >
                  <div onClick={() => setShowSignupModal(true)}>
                    <PrimaryActionButton
                      buttonText="Sign Up"
                      className={classes.signupButton}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NonLoggedInNavigationBar;
