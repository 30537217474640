import { createContext, useEffect, useState } from "react";
import "./App.css";

// third party packages & components
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// custom components
import LoggedInBaseLayout from "./components/LoggedInBaseLayout";
import NonLoggedInBaseLayout from "./components/NonLoggedInBaseLayout";
import NonLoggedInHomePage from "./pages/NonLoggedInHomePage";
import LoggedInHomePage from "./pages/LoggedInHomePage";
import GroupDetailsPage from "./pages/GroupDetailsPage";
import SuperAdmin from "./pages/SuperAdmin";
import useHttp from "./custom-hooks/useHttp";
import { HomePageActions } from "./actions/HomePageActions";
import { HomePageLoader } from "./loaders/HomePageLoaders";
import { GroupDetailsPageLoader } from "./loaders/GroupDetailsPageLoader";
import { GroupDetailsPageActions } from "./actions/GroupDetailsPageActions";
import { SuperAdminLoader } from "./loaders/SuperAdminLoader";
import useCheckIfAuthenticated from "./custom-hooks/useRenewAccessToken";
import ResetPassword from "./pages/ResetPassword";
import EditProfilePage from "./pages/EditProfilePage";
import { EditProfileLoader } from "./loaders/EditProfileLoader";

// context
export const authContext = createContext();
export const loginContext = createContext();
export const signupContext = createContext();
export const forgotPasswordContext = createContext();

function App() {
  const { sendRequest } = useHttp();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const { checkIfAuthenticated } = useCheckIfAuthenticated();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // status = true/false, value = user object
  const setAuth = (status, value = null) => {
    if (status) {
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      localStorage.setItem("user", JSON.stringify(value));
    } else {
      localStorage.setItem("isLoggedIn", JSON.stringify(false));
      localStorage.removeItem("user");

      window.history.pushState({}, "", "/");
    }

    setIsAuthenticated(status);
  };

  // this method :
  // 1. Checks if the user is logged in
  // 2. Checks if the user's access token is expired
  // 3. If the user's access token is expired, renew the token
  useEffect(() => {
    checkIfAuthenticated(setAuth);
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: isAuthenticated ? (
        <authContext.Provider value={{ isAuthenticated, setAuth }}>
          <LoggedInBaseLayout />
        </authContext.Provider>
      ) : (
        <authContext.Provider value={{ isAuthenticated, setAuth }}>
          <loginContext.Provider value={{ showLoginModal, setShowLoginModal }}>
            <signupContext.Provider
              value={{ showSignupModal, setShowSignupModal }}
            >
              <forgotPasswordContext.Provider
                value={{ showForgotPasswordModal, setShowForgotPasswordModal }}
              >
                <NonLoggedInBaseLayout />
              </forgotPasswordContext.Provider>
            </signupContext.Provider>
          </loginContext.Provider>
        </authContext.Provider>
      ),
      action: HomePageActions(sendRequest),
      children: [
        {
          index: true,
          loader: HomePageLoader(sendRequest),
          element: isAuthenticated ? (
            <LoggedInHomePage />
          ) : (
            <NonLoggedInHomePage />
          ),
        },

        {
          path: "/group/:id/:name",
          element: <GroupDetailsPage />,
          loader: GroupDetailsPageLoader(sendRequest),
          action: GroupDetailsPageActions(sendRequest),
        },
        {
          path: "/profile/edit",
          element: <EditProfilePage />,
          loader: EditProfileLoader(sendRequest),
        },
        {
          path: "/admin",
          element: <SuperAdmin />,
          loader: SuperAdminLoader(sendRequest),
        },
        {
          path: "/reset-password/:password_token",
          element: <ResetPassword />,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
