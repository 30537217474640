import React, { useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import classes from "./CreateGroupModal.module.css";
import PrimaryActionButton from "./PrimaryActionButton";
import { toast } from "react-toastify";
import { useSubmit } from "react-router-dom";
import FormikSelectInput from "./FormikSelectInput";
import { homePageApiResponseContext } from "./LoggedInBaseLayout";
import { BeatLoader } from "react-spinners";

import { researchTypes, specialties } from "../data/constants";
import FormikInputField from "./FormikInputField";
import useHttp from "../custom-hooks/useHttp";

export default function CreateGroupModal(props) {
  const { sendRequestAndTrackProgress, actionData, isLoading } = useHttp();
  const { navigation } = useContext(homePageApiResponseContext);

  const initialValues = {
    supervisor: "",
    leader: "",
    idea: "",
    participants: "",
    type: "",
    speciality: "",
  };

  const onSubmit = (values) => {
    const data = {
      isIdea: JSON.parse(values.idea),
      isSuperVisor: JSON.parse(values.supervisor),
      isLeader: JSON.parse(values.leader),
      participants: parseInt(values.participants),
      type: parseInt(values.type),
      speciality: parseInt(values.speciality),
    };
    sendRequestAndTrackProgress("post", "/create-group", data);
  };

  const validationSchema = Yup.object().shape({
    supervisor: Yup.string().required("Type is required"),
    leader: Yup.string().required("Type is required"),
    idea: Yup.string().required("Type is required"),
    participants: Yup.number().required("Type is required").min(4).max(12),
    type: Yup.string().required("Type is required"),
    speciality: Yup.string().required("Speciality is required"),
  });

  // perform necessary actions once a response is received for the create-group API request
  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        props.onHide();
        toast.success("Group created successfully");
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
          actionData.error?.message ||
          "An error occurred. Please try again later."
        );
      }
    }
  }, [actionData]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={classes.customModalBody}>
        <div className={classes.modalCloseButtonContainer}>
          <span
            className={`material-symbols-outlined ${classes.modalCloseButton}`}
            onClick={() => props.onHide()}
          >
            close
          </span>
        </div>
        <div className={classes.modalBodyContainer}>
          <h3 className={classes.title}>Create Group</h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className={classes.formRow}>
                <FormikSelectInput
                  options={[
                    { value: true, key: "Yes" },
                    { value: false, key: "No" },
                  ]}
                  defaultValue="Select"
                  name="supervisor"
                  label="Do you already have a supervisor"
                  required={true}
                />
              </div>
              <div className={classes.formRow}>
                <FormikSelectInput
                  options={[
                    { value: true, key: "Yes" },
                    { value: false, key: "No" },
                  ]}
                  defaultValue="Select"
                  name="leader"
                  label="Are you willing to be the leader"
                  required={true}
                />
              </div>
              <div className={classes.formRow}>
                <FormikSelectInput
                  options={[
                    { value: true, key: "Yes" },
                    { value: false, key: "No" },
                  ]}
                  defaultValue="Select"
                  name="idea"
                  label="Do you already have a research idea?"
                  required={true}
                />
              </div>
              <div className={classes.formRow}>
                <FormikSelectInput
                  options={researchTypes}
                  defaultValue="Select Study Design"
                  name="type"
                  label="Study Design"
                  required={true}
                />
              </div>
              <div className={classes.formRow}>
                <FormikSelectInput
                  options={specialties}
                  defaultValue="Select Speciality"
                  name="speciality"
                  label="Speciality"
                  required={true}
                />
              </div>
              <div className={classes.formRow}>
                <FormikInputField
                  label="Participants Count"
                  inputType="number"
                  fieldName="participants"
                  required={true}
                />
              </div>
              <PrimaryActionButton
                type="submit"
                className={classes.submitBtn}
                disabled={navigation.state === "idle" ? false : true}
                style={{
                  cursor:
                    navigation.state === "idle" ? "pointer" : "not-allowed",
                }}
                buttonText={
                  navigation.state === "idle" ? (
                    <p className={classes.submitBtnText}>Create Group</p>
                  ) : (
                    <BeatLoader color="white" loading size={10} />
                  )
                }
              />
            </Form>
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
