import { useState } from "react";
import classes from "./GroupCard.module.css";
import eyeIcon from "../images/eye.png";
import starIcon from "../images/starIcon.png";
import PrimaryActionButton from "./PrimaryActionButton";
import GroupJoinModal from "./GroupJoinModal";

const LoggedInGroupCard = (group) => {
  const [showjoinModal, setShowJoinModal] = useState(false);

  return (
    <div className={`col-auto ${classes.cardContainer}`}>
      <div className={classes.card}>
        {/*
          Old notice bar
        <div className={`${classes.noticeBar}`}>
          <div className={`${classes.iconContainer} col-auto`}>
            <img src={eyeIcon} alt="img" />
          </div>
          <div className={`${classes.noticeTextContainer} col`}>
            <p className={classes.noticeText}>leader opportunity</p>
          </div>
        </div>*/}
        {group.isFeatured && <img src={starIcon} alt="star" className={`${classes.starIcon}`} />}
        <h5 className={`${classes.groupTitle}`}>
          {/* {group.speciality.label} {group.type.label} */}
          {group.title}
          
        </h5>
        <p className={`${classes.availableSeats}`}>
          {group.seatsAvailable} seats left
          <p className={`${classes.participantsCount}`}>
          {group.participants} Participants
        </p>
        </p>
        
        <p className={`${classes.category}`}>{group.speciality.label}</p>
        <p className={`${classes.subCategory}`}>{group.type.label}</p>
        <div className={`${classes.statusBar} d-flex flex-wrap`}>
          { group.isIdea && 
          <div className={`${classes.noticeTextContainer} ${classes.statusItem} col`}>
            <p className={classes.noticeText}>Titled</p>
          </div>}
            
          {group.isLeader  && <div className={`${classes.noticeTextContainer} ${classes.statusItem} col`}>
            <p className={classes.noticeText}>On Lead</p>
          </div>}
          {group.isSuperVisor  && <div className={`${classes.noticeTextContainer} ${classes.statusItem} col`}>
            <p className={classes.noticeText}>Supervised</p>
          </div>}
        </div>
        <PrimaryActionButton
          buttonText="join"
          className={classes.actionButton}
          onClick={() => setShowJoinModal(true)}
        />
      </div>
      <GroupJoinModal
        show={showjoinModal}
        onHide={() => setShowJoinModal(false)}
        group={group}
      />
    </div>
  );
};

export default LoggedInGroupCard;
