const deleteGroup = (formData, sendRequest) => {
  const { groupId } = formData;

  let data = {
    groupId,
  };

  const resolved = (response) => {
    return { status: "ok", intent: "delete-group", response };
  };

  const rejected = (error) => {
    return { status: "error", intent: "delete-group", error };
  };

  return sendRequest("post", "/group/delete", data, resolved, rejected);
};

const acceptDelineJoinRequest = (formData, sendRequest) => {
  const { participantId, action, groupId } = formData;

  const data = {
    participantId,
    action,
  };

  const resolved = (response) => {
    return { status: "ok", intent: "handle-join", response };
  };

  const rejected = (error) => {
    return { status: "error", intent: "handle-join", error };
  };

  return sendRequest(
    "post",
    `/${groupId}/change-status`,
    data,
    resolved,
    rejected
  );
};

export const GroupDetailsPageActions =
  (sendRequest) =>
  async ({ request }) => {
    const formData = await request.formData();
    let intent = formData.get("intent");
    const data = Object.fromEntries(formData);

    if (intent === "delete-group") {
      return deleteGroup(data, sendRequest);
    }

    if (intent === "handle-join") {
      return acceptDelineJoinRequest(data, sendRequest);
    }

    throw { message: "Invalid intent", status: 400 };
  };
