import { useContext } from "react";
import handIcon from "../images/handIcon.png";
import classes from "./WelcomeMessage.module.css";
import { userContext } from "../components/LoggedInBaseLayout";

const WelcomeMessage = ({ name }) => {
  const user = useContext(userContext);
  const userDetails = user?.userDetails;
  return (
    <div className="d-flex align-items-center">
      {name ? (
        <p className={`${classes.welcomeMessage}`}>Welcome {name}</p>
      ) : (
        <p className={`${classes.welcomeMessage}`}>
          Welcome {userDetails?.name}
        </p>
      )}
      <img src={handIcon} alt="img" />
    </div>
  );
};

export default WelcomeMessage;
