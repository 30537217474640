import React, { useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./SignupModal.module.css";
import LoginModalInput from "./LoginModalInput";
import { authContext, forgotPasswordContext, signupContext } from "../App";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PrimaryActionButton from "./PrimaryActionButton";
import { useActionData, useSubmit, useNavigation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

export default function LoginModal(props) {
  const { setAuth } = useContext(authContext);
  const { setShowForgotPasswordModal } = useContext(forgotPasswordContext);
  const submit = useSubmit();
  const actionData = useActionData();
  const navigation = useNavigation();
  const { setShowSignupModal } = useContext(signupContext);

  // initial values passed to the form
  const initialValues = {
    email: "",
    password: "",
  };

  //form validation rules
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const onSubmit = (values) => {
    submit({ intent: "signin", ...values }, { method: "post", action: "/" });
  };

  const handleShowForgotPasswordModal = () => {
    // closes the login modal
    props.onHide();
    setShowForgotPasswordModal(true);
  };

  useEffect(() => {
    if (actionData && actionData.intent === "signin") {
      if (actionData.status === "ok") {
        // hides the loggin modal
        props.onHide();
        setAuth(true, actionData.response.data);
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            actionData.error?.message ||
            "An error occurred. Please try again later."
        );
      }
    }
  }, [actionData]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={classes.customModalBody}>
        <div className={classes.modalCloseButtonContainer}>
          <span
            className={`material-symbols-outlined ${classes.modalCloseButton}`}
            onClick={() => props.onHide()}
          >
            close
          </span>
        </div>
        <div className={classes.modalBodyContainer}>
          <h3 className={classes.title}>Login</h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className={classes.formRow}>
                <LoginModalInput
                  label="Email"
                  inputType="email"
                  fieldName="email"
                />
              </div>
              <div className={classes.formRow}>
                <LoginModalInput
                  label="Password"
                  inputType="password"
                  fieldName="password"
                />
              </div>
              <PrimaryActionButton
                type="submit"
                className={classes.submitBtn}
                disabled={navigation.state === "idle" ? false : true}
                style={{
                  cursor:
                    navigation.state === "idle" ? "pointer" : "not-allowed",
                }}
                buttonText={
                  navigation.state === "idle" ? (
                    <p className={classes.submitBtnText}>login</p>
                  ) : (
                    <BeatLoader color="white" loading size={10} />
                  )
                }
              />
            </Form>
          </Formik>
        </div>
        <p className={classes.loginText} style={{ marginBottom: "20px" }}>
          Haven't registerd yet?{" "}
          <span
            className={classes.loginButton}
            onClick={() => {
              props.onHide();
              setShowSignupModal(true);
            }}
          >
            sign up
          </span>
        </p>
        <div className={classes.bottomTextDiv}>
          <p
            onClick={handleShowForgotPasswordModal}
            className={classes.bottomTextLink}
          >
            Forgot your password ?
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
