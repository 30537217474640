import { useContext } from "react";
import classes from "./GroupCardTyper.module.css";
import eyeIcon from "../images/eye.png";
import starIcon from "../images/starIcon.png";
import PrimaryActionButton from "./PrimaryActionButton";
import { loginContext } from "../App";
import ReactTypingEffect from 'react-typing-effect';
import { researchTypes, specialties } from "../data/constants";

const GroupCard = (group) => {
  const { setShowLoginModal } = useContext(loginContext);

  return (
    <div className={`col-auto ${classes.cardContainer}`}>
      <div className={classes.card}>
        {/*
          Old notice bar
        <div className={`${classes.noticeBar}`}>
          <div className={`${classes.iconContainer} col-auto`}>
            <img src={eyeIcon} alt="img" />
          </div>
          <div className={`${classes.noticeTextContainer} col`}>
            <p className={classes.noticeText}>leader opportunity</p>
          </div>
        </div>*/}
        {group.isFeatured && <img src={starIcon} alt="star" className={`${classes.starIcon}`} />}
        <h5 className={`${classes.groupTitle}`}>
          {/* {group.speciality.label} {group.type.label} */}
          {group.title}
          
        </h5>
        <p className={`${classes.availableSeats}`}>
        <ReactTypingEffect
          staticText={"Seats left"}
          text={["5","8","10","12","9","11","6","5"]}
          speed={100}
          eraseSpeed={100}
          typingDelay={200}
          eraseDelay={400}
      />
      <p><ReactTypingEffect
          className={`${classes.participantsCount} ${classes.availableSeats}`}
          staticText={"participants"}
          text={["8","7","10","12","9","11","6","5"]}
          cursor={" "}
          speed={100}
          eraseSpeed={100}
          typingDelay={200}
          eraseDelay={400}
      /></p>
      </p>
        
        <p className={`${classes.category}`}>
          <ReactTypingEffect
          className=""
          text={specialties.map(item => item.key)}
          speed={100}
          eraseSpeed={100}
          typingDelay={200}
          eraseDelay={400}
          cursor={" "}
          />
      </p>
          <ReactTypingEffect
        text={researchTypes.map(item => item.key)}
        className={`${classes.subCategory}`}
        speed={100}
        eraseSpeed={100}
        typingDelay={200}
        eraseDelay={400}
        cursor={" "}
          />
        <div className={`${classes.statusBar} d-flex flex-wrap`}>
          { group.isIdea && 
          <div className={`${classes.noticeTextContainer} ${classes.statusItem} col`}>
            <p className={classes.noticeText}>Titled</p>
          </div>}
            
          {group.isLeader  && <div className={`${classes.noticeTextContainer} ${classes.statusItem} col`}>
            <p className={classes.noticeText}>On Lead</p>
          </div>}
          {group.isSuperVisor  && <div className={`${classes.noticeTextContainer} ${classes.statusItem} col`}>
            <p className={classes.noticeText}>Supervised</p>
          </div>}
        </div>
        <PrimaryActionButton
          buttonText="join"
          className={classes.actionButton}
          onClick={() => setShowLoginModal(true)}
        />
      </div>
    </div>
  );
};

export default GroupCard;
