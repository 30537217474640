import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignupModal";
import Footer from "./Footer";

// custom components
import { loginContext, signupContext, forgotPasswordContext } from "../App";
import NonLoggedInNavigationBar from "./NonLoggedInNavigationBar";
import ForgotPasswordModal from "./ForgotPasswordModal";

const NonLoggedInBaseLayout = () => {
  const { showLoginModal, setShowLoginModal } = useContext(loginContext);
  const { showSignupModal, setShowSignupModal } = useContext(signupContext);
  const { showForgotPasswordModal, setShowForgotPasswordModal } = useContext(
    forgotPasswordContext
  );

  return (
    <>
      <NonLoggedInNavigationBar />
      <Outlet />
      <Footer />
      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />
      <SignUpModal
        show={showSignupModal}
        onHide={() => setShowSignupModal(false)}
      />
      <ForgotPasswordModal
        show={showForgotPasswordModal}
        onHide={() => setShowForgotPasswordModal(false)}
      />
    </>
  );
};

export default NonLoggedInBaseLayout;
