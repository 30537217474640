import classes from "./PartnerCard.module.css";

const PartnerCard = ({ message, name, desc, image }) => {
  return (
    <div className={`col-lg-4 col-md-6 col-sm-12 ${classes.partnerCard}`}>
      <div
        className={`${classes.card} d-flex flex-column justify-content-between`}
      >
        <div className="feedbackContainer">
          <p className={classes.feedbackContent}>{message}</p>
        </div>
        <div
          className={`${classes.authorDetaislContainer} row align-items-center`}
        >
          <div className="col-auto" style={{ paddingRight: "4px" }}>
            <div
              className={classes.authorImageContainer}
              style={{
                backgroundImage: `url("${image}")`,
              }}
            ></div>
          </div>
          <div className="col">
            <div className={classes.userDataContainer}>
              <p className={classes.userName}>{name}</p>
              <p className={classes.userDescription}>
                {desc}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerCard;
