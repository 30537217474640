import { useState } from "react";
import classes from "./HomePageMyGroupsSection.module.css";
import PrimaryActionButton from "../components/PrimaryActionButton";
import arrowIcon from "../images/arrowIcon.png";
import HomePageGroupItem from "../components/HomePageGroupItem";

const HomePageMyGroupsSection = ({ groups, setShowCreateGroupModal }) => {
  const [expandGroups, setExpandGroups] = useState(true);

  return (
    <div className={`container ${classes.myGroupsContainer}`}>
      <PrimaryActionButton
        buttonText="create your group"
        className={`${classes.createNewGroupButton} d-md-none d-block`}
        onClick={() => setShowCreateGroupModal(true)}
      />
      <div className={`row`}>
        <div>
          <div className="accordion-item">
            <div
              className={`d-flex justify-content-between ${classes.myGroupButtonsContainer}`}
            >
              <div
                className={`col-auto d-flex align-items-center ${classes.myGroupsButton}`}
                onClick={() => setExpandGroups((prevState) => !prevState)}
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <img
                  className={`
                ${expandGroups ? classes.expand : null}
                ${classes.expandCollapseIcon}
              `}
                  src={arrowIcon}
                  alt="img"
                />
                <h1 className={`${classes.pageSubTitle}`}>My Groups</h1>
              </div>
              <div className="col-auto d-md-block d-none">
                <PrimaryActionButton
                  buttonText="create your group"
                  className={classes.createNewGroupButton}
                  onClick={() => setShowCreateGroupModal(true)}
                />
              </div>
            </div>
            <div
              id="collapseOne"
              className={`accordion-collapse collapse show ${classes.accordionBody}`}
              data-bs-parent="#accordionExample"
            >
              {groups.length === 0 ? (
                <div className="d-flex justify-content-start align-items-center">
                  <p className={`${classes.emptyGroupsMessage}`}>
                    Currently you do not have any groups to show
                  </p>
                </div>
              ) : (
                groups.map((group, key) => (
                  <div className="col-12" key={key}>
                    <HomePageGroupItem
                      id={group.id}
                      title={group.title}
                      name={`${group.speciality.label} ${group.type.label}`}
                      requests={group.requests}
                      participantsCount={group.participants}
                      category={group.speciality.label}
                      subCategory={group.type.label}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageMyGroupsSection;
