import React from "react";
import classes from "./FormikSelectInput.module.css";
import { Field, ErrorMessage } from "formik";

const FormikSelectInput = ({
  options,
  name,
  label,
  defaultValue,
  required,
}) => {
  const optionElements =
    options &&
    options.map((option) => (
      <option
        key={option.value}
        value={option.value}
        className={classes.selectOptions}
      >
        {option.key}
      </option>
    ));

  return (
    <>
      <label htmlFor={name} className={classes.label}>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
        <span className={classes.errorMessage}>
          <ErrorMessage name={name} />
        </span>
      </label>
      <div
        className={`position-relative ${classes.inputSelectTextAreaContainer}`}
      >
        <Field
          as="select"
          id={name}
          name={name}
          className={`${classes.inputSelectTextArea}`}
        >
          <option
            style={{ display: "none" }}
            key="none"
            value="none"
            defaultChecked
          >
            {defaultValue}
          </option>
          {optionElements}
        </Field>
        <span className={`material-symbols-outlined ${classes.dropDownArrow}`}>
          arrow_drop_down
        </span>
      </div>
    </>
  );
};

export default FormikSelectInput;
