import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import classes from "./CreateGroupModal.module.css";
import PrimaryActionButton from "./PrimaryActionButton";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import LoginModalInput from "./LoginModalInput";
import useHttp from "../custom-hooks/useHttp";

export default function RestPasswordmodal(props) {
  const navigate = useNavigate();
  const { password_token } = useParams();
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();

  const onSubmit = (values) => {
    const data = {
      password: values.password,
      password_token,
    };

    sendRequestAndTrackProgress(
      "post",
      `/password-reset/${password_token}`,
      data
    );
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("This field is required"),
    confirmPassword: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
  });

  //   perform necessary actions once a response is received for the reset-password API request
  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        toast.success(
          "Password reset successfully. Please sign in using your new password."
        );
        navigate("/");
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            actionData.error?.message ||
            "An error occurred. Please try again later."
        );
      }
    }
  }, [actionData]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={classes.customModalBody}>
        <div className={classes.modalCloseButtonContainer}>
          <span
            className={`material-symbols-outlined ${classes.modalCloseButton}`}
            onClick={() => props.onHide()}
            style={{ visibility: "hidden" }}
          >
            close
          </span>
        </div>
        <div className={classes.modalBodyContainer}>
          <h3 className={classes.title}>Reset Password</h3>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className={classes.formRow}>
                <LoginModalInput
                  label="New Password"
                  inputType="password"
                  fieldName="password"
                />
              </div>
              <div className={classes.formRow}>
                <LoginModalInput
                  label="Confirm New Password"
                  inputType="password"
                  fieldName="confirmPassword"
                />
              </div>
              <PrimaryActionButton
                type="submit"
                className={classes.submitBtn}
                disabled={isLoading ? true : false}
                style={{
                  cursor: isLoading ? "not-allowed" : "pointer",
                }}
                buttonText={
                  isLoading ? (
                    <BeatLoader color="white" loading size={10} />
                  ) : (
                    <p className={classes.submitBtnText}>Submit</p>
                  )
                }
              />
            </Form>
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
