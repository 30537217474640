const LoginAction = (formData, sendRequest) => {
  const { email, password } = formData;

  let data = {
    email,
    password,
  };

  const resolved = (response) => {
    return { status: "ok", intent: "signin", response };
  };

  const rejected = (error) => {
    return { status: "error", intent: "signin", error };
  };

  return sendRequest("post", "/login", data, resolved, rejected);
};

const forgotPasswordAction = (formData, sendRequest) => {
  const { email } = formData;

  let data = {
    email,
  };

  const resolved = (response) => {
    return { status: "ok", intent: "forgot-password", response };
  };

  const rejected = (error) => {
    return { status: "error", intent: "forgot-password", error };
  };

  return sendRequest("post", "/password-reset", data, resolved, rejected);
};

const CreateGroupAction = (formData, sendRequest) => {
  const { isIdea, isSuperVisor, isLeader, participants, type, speciality } = formData;

  let data = {
    isIdea: JSON.parse(isIdea),
    isSuperVisor: JSON.parse(isSuperVisor),
    isLeader: JSON.parse(isLeader),
    participants: parseInt(participants),
    type: parseInt(type),
    speciality: parseInt(speciality),
  };

  const resolved = (response) => {
    return { status: "ok", intent: "create-group", response };
  };

  const rejected = (error) => {
    return { status: "error", intent: "create-group", error };
  };

  return sendRequest("post", "/create-group", data, resolved, rejected);
};

// This method is responsible for returning the appropriate action(method which call the necessary API endpoint)
// based on the intent of the form data
// All the actions are defined above in this file
export const HomePageActions =
  (sendRequest) =>
    async ({ request }) => {
      const formData = await request.formData();
      let intent = formData.get("intent");
      const data = Object.fromEntries(formData);

      if (intent === "signin") {
        return LoginAction(data, sendRequest);
      }

      if (intent === "forgot-password") {
        return forgotPasswordAction(data, sendRequest);
      }

      if (intent === "create-group") {
        return CreateGroupAction(data, sendRequest);
      }

      throw { message: "Invalid intent", status: 400 };
    };
