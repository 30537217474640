import React from "react";
import classes from "./LoginModalInput.module.css";

import { Field, ErrorMessage } from "formik";

const FormikInputField = ({
  label,
  fieldName,
  inputType,
  required,
  className,
  disabled = false,
}) => {
  return (
    <div className={`${classes.componentDiv} ${className}`}>
      <label htmlFor={fieldName} className={classes.label}>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
        <span className={classes.errorMessage}>
          <ErrorMessage name={fieldName} />
        </span>
      </label>
      <Field
        type={inputType}
        id={fieldName}
        name={fieldName}
        className={classes.inputBox}
        disabled={disabled}
        autoComplete="off"
      />
    </div>
  );
};

export default FormikInputField;
