import { useState, useEffect } from "react";
import classes from "./LoggedInHomePage.module.css";
import filterIcon from "../images/filterIcon.png";
import SingleCategoryItem from "../components/SingleCategoryItem";
import GroupCarousel from "../components/GroupCarousel";
import WelcomeMessage from "../components/WelcomeMessage";
import CreateGroupModal from "../components/CreateGroupModal";
import { useLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import HomePageMyGroupsSection from "../components/HomePageMyGroupsSection";

const LoggedInHomePage = () => {
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [threeGroups, setThreeGroups] = useState([]);
  const loaderData = useLoaderData();
  const [filterGroups, setFilterGroups] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (loaderData) {
      const { myGroups, threeGroups } = loaderData;

      myGroups
        .then(({ response }) => {
          setGroups(response.data.msg);
        })
        .catch(({ error }) => {
          console.log(error?.response?.data?.msg);
          // toast.error(
          //   error?.response?.data?.msg || "Faild to fetch user groups"
          // );
        });

      threeGroups
        .then(({ response }) => {
          setThreeGroups(response.data.msg);
        })
        .catch(({ error }) => {
          toast.error(
            error?.response?.data?.msg || "Faild to fetch three groups"
          );
        });
    }
  }, [loaderData]);

  useEffect(() => {
    if (threeGroups.length > 0) {
      const categories = threeGroups.map((group) => group.type.label);
      const uniqueCategories = [...new Set(categories)];
      setCategories(uniqueCategories);
    }
  }, [threeGroups]);

  return (
    <section className={classes.homePage}>
      <div className={`container ${classes.welcomeMessageContainer}`}>
        <WelcomeMessage />
      </div>
      <HomePageMyGroupsSection
        groups={groups}
        setShowCreateGroupModal={setShowCreateGroupModal}
      />
      {threeGroups.length > 0 && (
        <div className={`container ${classes.joinGroup}`}>
          <div className="accordion-item">
            <div className={`d-flex ${classes.joinGroupTitleContianer}`}>
              <div className="d-flex align-items-center">
                <h1 className={`${classes.pageSubTitle}`}>Join a Group</h1>
              </div>
              <div
                className={`col-auto d-flex align-items-center ${classes.myGroupsButton}`}
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div className="d-flex align-items-center">
                  <img
                    className={classes.filterIcon}
                    src={filterIcon}
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div
              id="collapseTwo"
              className={`accordion-collapse collapse show ${classes.accordionBody}`}
              data-bs-parent="#accordionExample"
            >
              <div className={`row g-lg-4 g-2 ${classes.categoriesContainer}`}>
                {categories.map((category, key) => (
                  <div className="col-auto" key={key}>
                    <SingleCategoryItem
                      category={category}
                      filterGroup={filterGroups}
                      setFilterGroups={setFilterGroups}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={classes.groupCarouselContainer}>
            <GroupCarousel
              groups={
                filterGroups
                  ? threeGroups.filter(
                      (group) =>
                        group.type.label.toLowerCase() ===
                        filterGroups.toLowerCase()
                    )
                  : threeGroups
              }
            />
          </div>
        </div>
      )}
      <CreateGroupModal
        show={showCreateGroupModal}
        onHide={() => setShowCreateGroupModal(false)}
      />
    </section>
  );
};

export default LoggedInHomePage;
