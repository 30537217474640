import classes from "./SingleCategoryItem.module.css";

const SingleCategoryItem = ({ category, setFilterGroups, filterGroup }) => {
  const filterGroups = () => {
    if (filterGroup === category) {
      setFilterGroups(null);
    } else {
      setFilterGroups(category);
    }
  };

  return (
    <div
      onClick={filterGroups}
      className={`${classes.categoryItem} ${
        filterGroup === category ? classes.activeCategoryItem : null
      }`}
    >
      <p className={classes.category}>{category}</p>
    </div>
  );
};

export default SingleCategoryItem;
