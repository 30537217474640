import { useState } from "react";
import DeleteGroupModal from "./DeleteGroupModal";
import LeaveGroupModal from "./LeaveGroupModal";
import classes from "./GroupItem.module.css";
import PrimaryActionButton from "./PrimaryActionButton";

const GroupDetailsPageGroupItem = ({
  id,
  name,
  title,
  participantsCount,
  category,
  subCategory,
  isOwner,
}) => {
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [showLeaveGroupModal, setShowLeaveGroupModal] = useState(false);

  return (
    <div className={`${classes.groupContainer}`}>
      <div className="row">
        <div className="col-lg col-12">
          <p>{title}</p>
          <p className={classes.groupName}>{name}</p>
          {!isOwner ? (
            <p className={`${classes.status} ${classes.accepted}`}>accepted</p>
          ) : (
            <p className={`${classes.status}`}></p>
          )}
          <p
            className={classes.metaData}
          >{`${participantsCount} Participants | ${category} | ${subCategory}`}</p>
        </div>
        <div className="col-lg-auto col-12 d-flex align-items-center justify-content-end">
          {isOwner ? (
            <PrimaryActionButton
              className={classes.actionButton}
              buttonText={"delete group ❌"}
              onClick={() => setShowDeleteGroupModal(true)}
            />
          ) : (
            <PrimaryActionButton
              className={classes.actionButton}
              buttonText="leave group 👋"
              onClick={() => setShowLeaveGroupModal(true)}
            />
          )}
        </div>
      </div>
      <DeleteGroupModal
        groupId={id}
        show={showDeleteGroupModal}
        onHide={() => setShowDeleteGroupModal(false)}
      ></DeleteGroupModal>
      <LeaveGroupModal
        groupId={id}
        show={showLeaveGroupModal}
        onHide={() => setShowLeaveGroupModal(false)}
      ></LeaveGroupModal>
    </div>
  );
};

export default GroupDetailsPageGroupItem;
