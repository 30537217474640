import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./SignupModal.module.css";
import LoginModalInput from "./LoginModalInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PrimaryActionButton from "./PrimaryActionButton";
import { useActionData, useSubmit, useNavigation } from "react-router-dom";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

export default function ForgotPasswordModal(props) {
  const submit = useSubmit();
  const actionData = useActionData();
  const navigation = useNavigation();

  // initial values passed to the form
  const initialValues = {
    email: "",
  };

  //form validation rules
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("This field is required!"),
  });

  const onSubmit = (values) => {
    submit(
      { intent: "forgot-password", ...values },
      { method: "post", action: "/" }
    );
  };

  useEffect(() => {
    if (actionData && actionData.intent === "forgot-password") {
      if (actionData.status === "ok") {
        toast.success("Password reset link sent to your email");
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            actionData.error?.message ||
            "An error occurred. Please try again later."
        );
      }
    }
  }, [actionData]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={classes.customModalBody}>
        <div className={classes.modalCloseButtonContainer}>
          <span
            className={`material-symbols-outlined ${classes.modalCloseButton}`}
            onClick={() => props.onHide()}
          >
            close
          </span>
        </div>
        <div className={classes.modalBodyContainer}>
          <h3 className={classes.title}>Forgot Password</h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className={classes.formRow}>
                <LoginModalInput
                  label="Email"
                  inputType="email"
                  fieldName="email"
                />
              </div>
              <PrimaryActionButton
                type="submit"
                className={`${classes.submitBtn} ${classes.forgotPasswordButton}`}
                disabled={navigation.state === "idle" ? false : true}
                style={{
                  cursor:
                    navigation.state === "idle" ? "pointer" : "not-allowed",
                }}
                buttonText={
                  navigation.state === "idle" ? (
                    <p className={classes.submitBtnText}>send</p>
                  ) : (
                    <BeatLoader color="white" loading size={10} />
                  )
                }
              />
            </Form>
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
