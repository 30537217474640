export const genders = [
  { value: 1, key: "Male" },
  { value: 2, key: "Female" },
];

export const years = [
  { value: "1", key: "First Year" },
  { value: "2", key: "Second Year" },
  { value: "3", key: "Third Year" },
  { value: "4", key: "Fourth Year" },
  { value: "5", key: "Fifth Year" },
  { value: "6", key: "Sixth Year" },
  { value: "7", key: "Intern" },
  { value: "8", key: "Resident" },
  { value: "9", key: "Registrar" },
  { value: "10", key: "Consultant" },
];

export const researchTypes = [
   {value: 1, key: "Cross-sectional"},
     {value: 2, key: "Systematic Reviews and Meta-analysis"},
     {value: 3, key: "Case Report"},
     {value: 4, key: "Case Series"},
     {value: 5, key: "Cohort Study"},
     {value: 6, key: "Retrospective Study"},
     {value: 7, key: "Narrative Reviews"},
     {value: 8, key: "Clinical Trial"},
     {value: 9, key: "Prospective Study"}
];


export const universities = [
   {value: 0, key: "None"},
   {value: 1, key: "King Saud University"},
   {value: 2, key: "Princess Nora bint Abdul Rahman University"},
   {value: 3, key: "Imam Muhammad bin Saud Islamic University"},
   {value: 4, key: "Prince Sultan University"},
   {value: 5, key: "College of Telecom & Information"},
   {value: 6, key: "Arab Open University"},
   {value: 7, key: "Riyadh College of Dentistry and Pharmacy"},
   {value: 8, key: "Al Yamamah University"},
   {value: 9, key: "Dar Al Uloom University"},
   {value: 10, key: "King Saud bin Abdulaziz University for Health Sciences"},
   {value: 11, key: "Alfaisal University"},
   {value: 12, key: "Arab East Colleges"},
   {value: 13, key: "AlMaarefa University"},
   {value: 14, key: "Prince Sattam Bin Abdulaziz University"},
   {value: 15, key: "Al Farabi College of Dentistry and Nursing"},
   {value: 16, key: "Technical Trainers College"},
   {value: 17, key: "Majmaah University"},
   {value: 18, key: "Shaqra University"},
   {value: 19, key: "Saudi Electronic University"},
   {value: 20, key: "King Abdulaziz University"},
   {value: 21, key: "Umm Al-Qura University"},
   {value: 22, key: "Leadership Community College"},
   {value: 23, key: "Jeddah College of Technology"},
   {value: 24, key: "Effat University"},
   {value: 25, key: "Dar Al-Hekma College"},
   {value: 26, key: "Fakeeh College for Medical Sciences"},
   {value: 27, key: "College of Business Administration (CBA)"},
   {value: 28, key: "Prince Sultan Aviation Academy"},
   {value: 29, key: "Taif University"},
   {value: 30, key: "Taif College of Technology"},
   {value: 31, key: "Batterjee Medical College"},
   {value: 32, key: "University of Jeddah"},
   {value: 33, key: "Prince Mohammad bin Salman College of Business and Entrepreneurship"},
   {value: 34, key: "Islamic University of Medina"},
   {value: 35, key: "Yanbu Industrial College"},
   {value: 36, key: "Al-Madinah College of Technology"},
   {value: 37, key: "Taibah University"},
   {value: 38, key: "Yanbu University College"},
   {value: 39, key: "Madinah Institute for Leadership and Entrepreneurship (MILE)"},
   {value: 40, key: "Prince Mugrin University"},
   {value: 41, key: "Mohammed Almana College of Medical Sciences"},
   {value: 42, key: "Dammam College of Technology"},
   {value: 43, key: "Dammam Community College"},
   {value: 44, key: "Al Ahsa College of Technology"},
   {value: 45, key: "King Fahd University for Petroleum and Minerals"},
   {value: 46, key: "Imam Abdulrahman Bin Faisal University"},
   {value: 47, key: "King Faisal University"},
   {value: 48, key: "Jubail Industrial College"},
   {value: 49, key: "Prince Sultan Military College of Health Sciences"},
   {value: 50, key: "University of Hafr Albatin"},
   {value: 51, key: "Jubail Technical Institute"},
   {value: 52, key: "University College of Jubail"},
   {value: 53, key: "Qatif College of Technology"},
   {value: 54, key: "University of Hafr al Batin"},
   {value: 55, key: "Prince Mohammad University"},
   {value: 56, key: "King Khalid University"},
   {value: 57, key: "IBN Rushd College for Management Sciences"},
   {value: 58, key: "College of Food and Environment Technology in Buraydah"},
   {value: 59, key: "Qassim University"},
   {value: 60, key: "Sulaiman Al Rajhi University"},
   {value: 61, key: "Al Jawf University"},
   {value: 62, key: "Jazan University"},
   {value: 63, key: "University of Hail"},
   {value: 64, key: "Al Baha University"},
   {value: 65, key: "Najran University"},
   {value: 66, key: "Northern Borders University"},
   {value: 67, key: "Tabuk University"},
   {value: 68, key: "Fahd bin Sultan University"},
   {value: 69, key: "Institute of Public Administration"},
   {value: 70, key: "Ibn Sina National College"},
   {value: 71, key: "University of Bisha"},
];

export const specialties =[
   {value: 1, key: "Internal Medicine"},
   {value: 2, key: "Pediatrics"},
   {value: 3, key: "Family Medicine"},
   {value: 4, key: "Emergency Medicine"},
   {value: 5, key: "Psychiatry"},
   {value: 6, key: "Obstetrics and Gynecology"},
   {value: 7, key: "General Surgery"},
   {value: 8, key: "Anesthesiology"},
   {value: 9, key: "Radiology"},
   {value: 10, key: "Orthopedic Surgery"},
   {value: 11, key: "Cardiology"},
   {value: 12, key: "Dermatology"},
   {value: 13, key: "Gastroenterology"},
   {value: 14, key: "Neurology"},
   {value: 15, key: "Ophthalmology"},
   {value: 16, key: "Otolaryngology (ENT)"},
   {value: 17, key: "Urology"},
   {value: 18, key: "Oncology"},
   {value: 19, key: "Nephrology"},
   {value: 20, key: "Pulmonology"},
   {value: 21, key: "Hematology"},
   {value: 22, key: "Infectious Diseases"},
   {value: 23, key: "Physical Medicine and Rehabilitation"},
   {value: 24, key: "Clinical Nutrition"},
   {value: 25, key: "Nutrition"},
   {value: 26, key: "Speech and Language Pathology"},
   {value: 27, key: "Medical Laboratory"},
   {value: 28, key: "Genetics and Stem Cells"},
   {value: 29, key: "Public Health"},
   {value: 30, key: "Global Health"},
   {value: 31, key: "Microbiology"},
   {value: 32, key: "Pathology"},
   {value: 33, key: "Palliative Care"},
   {value: 34, key: "Patient Safety"},
   {value: 35, key: "Health Education"},
   {value: 36, key: "Behavioral Sciences"},
   {value: 37, key: "Physical Therapy"},
   {value: 38, key: "Pharmacy"},
   {value: 39, key: "Dentistry"},
]

export const majors =[
   {value: 0, key: "None"},
   {value: 1, key: "Medicine"},
   {value: 2, key: "Pharmacy"},
   {value: 3, key: "Dentistry"},
   {value: 4, key: "Nursing"},
   {value: 5, key: "Allied Health Sciences"},
   {value: 6, key: "Public Health"},
   {value: 7, key: "Biomedical Sciences"},
   {value: 8, key: "Clinical Laboratory Sciences"},
   {value: 9, key: "Physical Therapy"},
   {value: 10, key: "Nutrition and Dietetics"},
   {value: 11, key: "Health Informatics"},
   {value: 12, key: "Occupational Therapy"},
]