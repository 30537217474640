import ResetPasswordModal from "../components/ResetPasswordModal";

const ResetPassword = () => {
  return (
    <section style={{ height: "100vh" }}>
      <div className="container">
        <ResetPasswordModal show={true} onHide={() => {}} />
      </div>
    </section>
  );
};

export default ResetPassword;
