import GroupCard from "./GroupCard";
import GroupCardTyper from "./GroupCardTyper";
import classes from "./GroupCarousel.module.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoggedInGroupCard from "./LoggedInGroupCard";

const GroupCarousel = ({ groups }) => {
  const isLoggedin = JSON.parse(localStorage.getItem("isLoggedIn"));

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={`row ${classes.GroupCarousel}`}>
      <Slider {...settings}>
        {groups.map((group, key) =>
          isLoggedin ? (
            <LoggedInGroupCard {...group} key={key} />
          ) : (
            <GroupCardTyper />

          )
        )}
      </Slider>
    </div>
  );
};

export default GroupCarousel;
