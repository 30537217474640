import { defer } from "react-router-dom";

const groupsLoader = (sendRequest) => (args) => {
  return sendRequest("get", "/admin/groups/").then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

const usersLoader = (sendRequest) => (args) => {
  return sendRequest("get", "/admin/users/").then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

export const SuperAdminLoader = (sendRequest) => async (args) => {
  const groups = groupsLoader(sendRequest)(args);
  const users = usersLoader(sendRequest)(args);

  return defer({
    groups,
    users,
  });
};
