import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import classes from "./SearchAndSelectInterestsField.module.css";
import TextField from "@mui/material/TextField";

const SearchAndSelectInterestsField = ({
  allInterests,
  required,
  setInterests,
  interestsError: error,
  setInterestsError,
  interestsErrorMessage,
  setInterestsErrorMessage,
}) => {
  const handleChange = (event, newValue) => {
    setInterests(newValue);

    if (newValue.length === 0) {
      setInterestsError(true);
      setInterestsErrorMessage("This field is required");
    } else {
      if (parseInt(newValue.length) > 3) {
        setInterestsError(true);
        setInterestsErrorMessage("You can select only 3 interests at most");
      } else {
        setInterestsError(false);
      }
    }
  };

  return (
    <div className={classes.autocompleteParent}>
      <label className={classes.label}>
        Interests
        {required && <span style={{ color: "red" }}>*</span>}
        {error && (
          <span className={classes.errorMessage}>{interestsErrorMessage}</span>
        )}
      </label>
      <Autocomplete
        onChange={handleChange}
        multiple
        limitTags={2}
        id="multiple-limit-tags"
        options={allInterests}
        getOptionLabel={(option) => option.key}
        renderInput={(params) => <TextField {...params} />}
        className={classes.autoCompleteInput}
        sx={{ width: "100%" }}
      />
    </div>
  );
};

export default SearchAndSelectInterestsField;
