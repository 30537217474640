import classes from "./ParticipantItem.module.css";
import PrimaryActionButton from "./PrimaryActionButton";
import whatsappIcon from "../images/whatsappLogo.png";
import emailIcon from "../images/emailLogo.png";
import telegramIcon from "../images/telegramLogo.png";
import useHttp from "../custom-hooks/useHttp";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const ParticipantItem = ({
  id,
  groupId,
  groupName,
  name,
  year,
  university,
  major,
  interests,
  email,
  phone,
  telegram,
  // here "isOwner" refers to the owner of the group. Not the owner of the participantItem
  isOwner,
  ownerId,
  isAccessedByAdmin = false,
}) => {
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();
  const navigate = useNavigate();

  const removeParticipant = () => {
    const data = {
      groupId,
      participantId: id,
    };

    sendRequestAndTrackProgress("post", `/remove/`, data);
  };

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        navigate(`/group/${groupId}/${groupName}`);
        toast.success(actionData?.response?.data?.msg);
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            "An error occurred. Please try again later."
        );
      }
    }
  }, [actionData]);

  return (
    <div className={`${classes.participantContainer}`}>
      <div className="row">
        <div className="col-lg col-12">
          <p className={classes.userName}>{name}</p>
          <p
            className={classes.metaData}
          >{`${year != "None" ? year + " | " : ""} ${university != "None" ? university + " | " : ""}  ${major != "None" ? major + " | " : ""} interested in ${interests
            .map((interest) => interest.label)
            .join(", ")}`}</p>
          <div className="row">
            <div className="col-auto">
              <a href={`https://wa.me/${phone}`} target="_blank">
                <img
                  className={classes.contactIcon}
                  src={whatsappIcon}
                  alt="img"
                />
              </a>
            </div>
            { telegram && <div className="col-auto">
              <a href={`https://t.me/${telegram}`} target="_blank">
                <img
                  className={`${classes.contactIcon} ${classes.telegramIcon}`}
                  src={telegramIcon}
                  alt="img"
                />
              </a>
            </div>}
            { email && <div className="col-auto">
              <a href={`mailto:${email}`}>
                <img
                  className={classes.contactIcon}
                  src={emailIcon}
                  alt="img"
                />
              </a>
            </div> }
          </div>
        </div>
        {isAccessedByAdmin ? (
          <div className="col-lg-auto col-12 d-flex align-items-center">
            <div className="d-lg-block d-md-flex d-block justify-content-between w-100">
              <div>
                <PrimaryActionButton
                  className={`${classes.actionButton} ${classes.adminActionButton} ${classes.removeButton}`}
                  buttonText="remove"
                />
              </div>
              <div>
                <PrimaryActionButton
                  className={`${classes.actionButton} ${classes.adminActionButton}`}
                  buttonText="view"
                />
              </div>
            </div>
          </div>
        ) : (
          // 1. only the owner can remove participants
          // 2. the owner can't remove himself
          isOwner &&
          ownerId !== id && (
            <div className="col-lg-auto col-12 d-flex align-items-center justify-content-end">
              <PrimaryActionButton
                className={classes.actionButton}
                buttonText={
                  isLoading ? (
                    <BeatLoader color="white" loading size={10} />
                  ) : (
                    "remove"
                  )
                }
                onClick={removeParticipant}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ParticipantItem;
