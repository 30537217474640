import { useState, useEffect } from "react";
import WelcomeMessage from "../components/WelcomeMessage";
import filterIcon from "../images/filterIcon.png";
import classes from "./SuperAdmin.module.css";
import ParticipantItem from "../components/ParticipantItem";
import { useLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import SuperAdminGroupDetailsItem from "../components/SuperAdminGroupDetailsItem";

const SuperAdmin = () => {
  const loaderData = useLoaderData();
  const [groups, setGroups] = useState([]);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    if (loaderData) {
      const { groups, users } = loaderData;

      groups
        .then(({ response }) => {
          setGroups(response.data.msg);
        })
        .catch(({ error }) => {
          toast.error(error?.response?.data?.msg || "Faild to fetch groups");
        });

      users
        .then(({ response }) => {
          setParticipants(response.data.msg);
        })
        .catch(({ error }) => {
          toast.error(error?.response?.data?.msg || "Faild to fetch users");
        });
    }
  }, [loaderData]);

  return (
    <section className={classes.adminPage}>
      <div className="container">
        <WelcomeMessage name="admin" />
      </div>
      <div className={`container ${classes.groupsContainer}`}>
        <div className="row g-sm-4 g-3">
          <div className="col-auto d-flex align-items-center">
            <h1 className={classes.pageSubTitle}>Groups</h1>
          </div>
          <div className="col">
            <input
              type="text"
              className={classes.searchInputBox}
              placeholder="Search Groups"
            />
          </div>
          <div className="col-auto d-sm-flex d-none align-items-center">
            <img className={classes.filterIcon} src={filterIcon} alt="img" />
          </div>
        </div>
        <div className={classes.groupListContainer}>
          {groups.map((group, key) => (
            <div className="col-12" key={key}>
              <SuperAdminGroupDetailsItem
                id={group.id}
                name={`${group.speciality.label} ${group.type.label}`}
                participantsCount={group.participants}
                category={group.speciality.label}
                subCategory={group.type.label}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={`container ${classes.groupsContainer}`}>
        <div className="row g-sm-4 g-3">
          <div className="col-auto d-flex align-items-center">
            <h1 className={classes.pageSubTitle}>Users</h1>
          </div>
          <div className="col">
            <input
              type="text"
              className={classes.searchInputBox}
              placeholder="Search Users"
            />
          </div>
          <div className="col-auto d-sm-flex d-none align-items-center">
            <img className={classes.filterIcon} src={filterIcon} alt="img" />
          </div>
        </div>
        <div className={classes.groupListContainer}>
          {participants.map((participant, key) => (
            <ParticipantItem
              key={key}
              id={participant.id}
              name={participant.name}
              year={participant.year.label}
              university={participant.university.label}
              interests={participant.intreset}
              email={participant.email}
              phone={participant.phone}
              // here "isOwner" refers to the owner of the group. Not the owner of the participantItem
              isOwner={false}
              isAccessedByAdmin={true}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SuperAdmin;
