import { defer } from "react-router-dom";

const userDataLoader = (sendRequest) => (args) => {
  return sendRequest("get", "/profile").then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

export const EditProfileLoader = (sendRequest) => async (args) => {
  const userData = userDataLoader(sendRequest)(args);

  return defer({
    userData,
  });
};
