import { useContext, useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom/dist";
import PrimaryActionButton from "../components/PrimaryActionButton";
import classes from "./NonLoggedInHomePage.module.css";
import coupleImage from "../images/couple.png";
import paperImage from "../images/paper.png";
import Newsletter from "../components/Newsletter";
import PartnersSection from "../components/PartnersSection";
import GroupCarousel from "../components/GroupCarousel";
import { signupContext } from "../App";
import { toast } from "react-toastify";

const NonLoggedInHomePage = () => {
  const { setShowSignupModal } = useContext(signupContext);
  const [threeGroups, setThreeGroups] = useState([]);
  const loaderData = useLoaderData();

  useEffect(() => {
    if (loaderData) {
      const { threeGroups } = loaderData;

      threeGroups
        .then(({ response }) => {
          setThreeGroups(response.data.msg);
        })
        .catch(({ error }) => {
          toast.error(
            error?.response?.data?.msg || "Faild to fetch three groups"
          );
        });
    }
  }, [loaderData]);

  return (
    <>
      <section className={classes.HomePage}>
        <div className="container">
          <div className={`row justify-content-center ${classes.topBanner}`}>
            <div className="col-auto">
              <div
                className={`${classes.pageTitleContainer} d-flex justify-content-center align-items-center`}
              >
                <h1 className={`${classes.pageTitle}`}>researchers hub</h1>
                <div className="d-flex">
                  <img
                    className={classes.paperImage}
                    src={paperImage}
                    alt="img"
                  />
                </div>
              </div>
              <div
                className={`${classes.pageSubTitleContainer} d-flex justify-content-center align-items-center`}
              >
                <h2 className={`${classes.pageSubTitle}`}>
                  join and make research groups
                </h2>
                <div className="d-flex">
                  <img
                    className={classes.coupleImage}
                    src={coupleImage}
                    alt="img"
                  />
                </div>
              </div>
              <div className="topBannerActionButtonContainer d-flex justify-content-center">
                <PrimaryActionButton
                  buttonText="join us"
                  className={classes.topBannerActionButton}
                  onClick={() => setShowSignupModal(true)}
                />
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className={`container ${classes.groupsContainer}`}>
            <GroupCarousel groups={threeGroups} />
          </div>
        </section>
        {/* <Newsletter /> */}
        <PartnersSection />
      </section>
    </>
  );
};

export default NonLoggedInHomePage;
