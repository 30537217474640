import { Link } from "react-router-dom";
import classes from "./Footer.module.css";
import NavBarBusinessLogo from "./NavBarBusinessLogo";
import facebookIcon from "../images/facebookIcon.png";
import instagramIcon from "../images/instaIcon.png";
import youtubeIcon from "../images/youtubeIcon.png";
import linkedinIcon from "../images/linkedinIcon.png";

const Footer = () => {
  return (
    <section className={classes.footerContainer}>
      <div className="container">
        <div className={classes.divider}></div>
        <div className="row justify-content-md-between justify-content-center">
          <div className="col-md-auto col-12 d-flex flex-md-column flex-row justify-content-md-between justify-content-center">
            <div className={classes.businessLogoContainer}>
              <Link to="/">
                <NavBarBusinessLogo />
              </Link>
            </div>
            {/*<div
              className={`d-md-flex d-none ${classes.socialMediaIconsContainer}`}
            >
              <img
                className={classes.socialIcon}
                src={facebookIcon}
                alt="icon"
              />
              <img
                className={classes.socialIcon}
                src={linkedinIcon}
                alt="icon"
              />
              <img
                className={classes.socialIcon}
                src={youtubeIcon}
                alt="icon"
              />
              <img
                className={classes.socialIcon}
                src={instagramIcon}
                alt="icon"
              />
            </div>
          </div>
          <div
            className={`col-md-auto col-12 d-md-flex d-block ${classes.footerNavSection}`}
          >
            <div className={`d-flex flex-column ${classes.footerNavContainer}`}>
              <p className={classes.footerLinkSectionTitle}>Topic</p>
              <Link to={`#`} className={classes.footerLink}>
                <p className={classes.footerLinkText}>page</p>
              </Link>
              <Link to={`#`} className={classes.footerLink}>
                <p className={classes.footerLinkText}>page</p>
              </Link>
              <Link to={`#`} className={classes.footerLink}>
                <p className={classes.footerLinkText}>page</p>
              </Link>
            </div>
            <div className={`d-flex flex-column ${classes.footerNavContainer}`}>
              <p className={classes.footerLinkSectionTitle}>Topic</p>
              <Link to={`#`} className={classes.footerLink}>
                <p className={classes.footerLinkText}>page</p>
              </Link>
              <Link to={`#`} className={classes.footerLink}>
                <p className={classes.footerLinkText}>page</p>
              </Link>
              <Link to={`#`} className={classes.footerLink}>
                <p className={classes.footerLinkText}>page</p>
              </Link>
            </div>
            <div className={`d-flex flex-column ${classes.footerNavContainer}`}>
              <p className={classes.footerLinkSectionTitle}>Topic</p>
              <Link to={`#`} className={classes.footerLink}>
                <p className={classes.footerLinkText}>page</p>
              </Link>
              <Link to={`#`} className={classes.footerLink}>
                <p className={classes.footerLinkText}>page</p>
              </Link>
              <Link to={`#`} className={classes.footerLink}>
                <p className={classes.footerLinkText}>page</p>
              </Link>
            </div>*/}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
