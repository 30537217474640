import { useState } from "react";
import classes from "./Chat.module.css";
import MessageItem from "./MessageItem";

const Chat = ({ sendMessage, messages }) => {
  const [newMessage, setNewMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // prevent empty messages
    if (newMessage.trim() !== "") {
      setNewMessage(""); // clear the input field after message is sent
      sendMessage(newMessage);
    }
  };

  return (
    <div className={classes.chatContainer}>
      <div className={classes.messagesContainer}>
        {messages &&
          messages.map((message, key) => (
            <MessageItem
              type={message.type}
              message={message.text}
              key={key}
              sender={message.sender}
            />
          ))}
      </div>

      <div className={classes.divider}></div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <input
              className={classes.newMessage}
              type="text"
              placeholder="Type Something..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
          </div>
          <div
            onClick={handleSubmit}
            className="col-auto"
            style={{ paddingLeft: "0" }}
          >
            <div className={classes.sendIconContainer}>
              <span className={`material-symbols-outlined ${classes.sendIcon}`}>
                send
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Chat;
