import { useNavigate } from "react-router-dom";
import useHttp from "../custom-hooks/useHttp";
import { useEffect } from "react";
import classes from "./LeaveGroupModal.module.css";

import PrimaryActionButton from "./PrimaryActionButton";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { Modal } from "react-bootstrap";

const LeaveGroupModal = ({ groupId, ...props }) => {
  const navigate = useNavigate();
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();

  const leaveGroupAction = () => {
    let data = {
      groupId,
    };
    sendRequestAndTrackProgress("post", "/leave/", data);
  };

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        toast.success("You have left the group successfully!");
        navigate("/");
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            "An error occurred. Please try again later."
        );
      }
    }
  }, [actionData]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={classes.customModalBody}>
        <div className={classes.modalCloseButtonContainer}>
          <span
            className={`material-symbols-outlined ${classes.modalCloseButton}`}
            onClick={() => props.onHide()}
          >
            close
          </span>
        </div>
        <div className={classes.modalBodyContainer}>
          <h3 className={classes.title}>Leave Group</h3>
          <p className="text-center">
            Are you sure you want to leave this group?
          </p>
          <div className="d-flex justify-content-center">
            <PrimaryActionButton
              onClick={props.onHide}
              className={`${classes.goBackButton} ${classes.submitBtn}`}
              buttonText={"Go Back"}
            />
            <PrimaryActionButton
              onClick={leaveGroupAction}
              className={classes.submitBtn}
              buttonText={
                isLoading ? <BeatLoader size={8} color="#fff" /> : "Yes"
              }
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LeaveGroupModal;
