import React, { createContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigation } from "react-router-dom";
import LoggedInNavigationBar from "./LoggedInNavigationBar";
import Footer from "./Footer";
import { useActionData, useLoaderData } from "react-router-dom";
import useHttp from "../custom-hooks/useHttp";
import { toast } from "react-toastify";

export const userContext = createContext();

// Because of the architecture we've used to create the router in app.js,
// there is no way to access the response data of any API request
// initiated in the logged-in-home page, within that page. But the response
// data can be accessed in loggedInBaseLayout(this) component through useActionData/useLoaderData hook.
// So, we need to pass the response data to the loggedInHomePage component.
// We can do this by creating a context and passing the response data to the loggedInHomePage component
export const homePageApiResponseContext = createContext();

const LoggedInBaseLayout = () => {
  const { pathname } = useLocation();
  const [userDetails, setUserDetails] = useState(null);
  const actionData = useActionData();
  const loaderData = useLoaderData();
  const navigation = useNavigation();
  const { sendRequestAndTrackProgress, actionData: userActionData } = useHttp();

  // automatically scrolls the page to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    sendRequestAndTrackProgress("get", "/profile", null);
  }, []);

  useEffect(() => {
    if (userActionData) {
      if (userActionData.status === "ok") {
        setUserDetails(userActionData.response.data.msg);
      } else if (userActionData.status === "error") {
        toast.error(
          userActionData.error?.response?.data?.msg ||
            "Something went wrong. Faild to fetch user details!"
        );
      }
    }
  }, [userActionData]);

  return (
    <userContext.Provider value={{ userDetails, setUserDetails }}>
      <homePageApiResponseContext.Provider
        value={{ loaderData, actionData, navigation }}
      >
        <LoggedInNavigationBar />
        <Outlet />
        <Footer />
      </homePageApiResponseContext.Provider>
    </userContext.Provider>
  );
};

export default LoggedInBaseLayout;
