import classes from "./MessageItem.module.css";

const MessageItem = ({ type, message, sender }) => {
  return (
    <div
      className={`d-flex ${
        type === "sent" ? "justify-content-end" : "justify-content-start"
      }`}
    >
      <div
        className={
          type === "sent"
            ? classes.sentMessageContainer
            : classes.recievedMessageContainer
        }
      >
        {type === "received" && <p className={classes.senderName}>{sender}</p>}
        <p
          className={
            type === "sent"
              ? classes.sentMessageText
              : classes.recievedMessageText
          }
        >
          {message}
        </p>
      </div>
    </div>
  );
};

export default MessageItem;
