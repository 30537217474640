import { useState, useEffect, useContext } from "react";
import GroupDetailsPageGroupItem from "../components/GroupDetailsPageGroupItem";
import WelcomeMessage from "../components/WelcomeMessage";
import classes from "./GroupDetailsPage.module.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Chat from "../components/Chat";
import ParticipantItem from "../components/ParticipantItem";
import RequestItem from "../components/RequestItem";
import { useLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import { userContext } from "../components/LoggedInBaseLayout";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKET_URL);

const GroupDetailsPage = () => {
  const [isOwner, setIsOwner] = useState(false);
  const [groupData, setGroupData] = useState(null);
  const user = useContext(userContext);
  const userDetails = user?.userDetails;
  const loaderData = useLoaderData();
  const [joinRequests, setJoinRequests] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLoggedinToChat, setIsLoggedinToChat] = useState(false);

  useEffect(() => {
    if (loaderData) {
      const { groupDetais, joinRequests, participants } = loaderData;

      groupDetais
        .then(({ response }) => {
          setGroupData(response.data.msg);
        })
        .catch(({ error }) => {
          toast.error(
            error?.response?.data?.msg || "Faild to fetch group data!"
          );
        });

      joinRequests
        .then(({ response }) => {
          setJoinRequests(response.data.msg);
        })
        .catch(({ error }) => {
          console.log(error?.response?.data?.msg);
        });

      participants
        .then(({ response }) => {
          setParticipants(response.data.msg);
        })
        .catch(({ error }) => {
          toast.error(
            error?.response?.data?.msg || "Faild to fetch participants"
          );
        });
    }
  }, [loaderData]);

  useEffect(() => {
    if (userDetails && groupData) {
      if (userDetails.id === groupData.owner) {
        setIsOwner(true);
      }
    }
  }, [groupData, userDetails]);

  // login-chat request should be sent once the component is mounted
  useEffect(() => {
    if (userDetails) {
      socket.emit("login", {
        message: {
          accessToken: JSON.parse(localStorage.getItem("user")).accessToken,
        },
      });

      socket.on("after login", function (e) {
        setIsLoggedinToChat(true);
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      socket.on("after join", function (e) {
        const chat = e.chat.map((message) => {
          if (message.senderId === userDetails.id) {
            return {
              type: "sent",
              ...message,
            };
          } else {
            return {
              type: "received",
              ...message,
            };
          }
        });

        setMessages(chat);
      });

      socket.on("roomMessage", (message) => {
        let newMessage;

        if (message.senderId === userDetails.id) {
          newMessage = { type: "sent", ...message };
        } else {
          newMessage = { type: "received", ...message };
        }
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      });
    }
  }, [userDetails]);

  // join-chat request should be sent once the login-chat request is successful
  useEffect(() => {
    if (isLoggedinToChat && groupData) {
      socket.emit("join", { message: { groupId: groupData.id } });
    }
  }, [isLoggedinToChat, groupData]);

  useEffect(() => {
    // Cleanup on component unmount
    return () => socket.emit("leave", { message: { groupId: groupData?.id } });
  }, [groupData]);

  // sends the new message trough the socket
  const sendMessage = (messageText) => {
    const message = {
      text: messageText,
      room: groupData.chat.room,
    };

    socket.emit("message", {
      message,
    });
  };

  return (
    groupData && (
      <section>
        <div className={`container ${classes.welcomeMessageContainer}`}>
          <WelcomeMessage />
        </div>
        <div className={`container ${classes.groupDetailsContainer}`}>
          <GroupDetailsPageGroupItem
            id={groupData.id}
            name={`${groupData.speciality.label} ${groupData.type.label}`}
            title={groupData.title}
            participantsCount={groupData.participants}
            category={groupData.speciality.label}
            subCategory={groupData.type.label}
            isOwner={isOwner}
          />
          <div className="row"></div>
        </div>
        <div className="container">
          <Tabs defaultFocus>
            <TabList className={`react-tabs__tab-list ${classes.tabList}`}>
              <Tab className={`react-tabs__tab ${classes.tabName}`}>chat</Tab>
              <Tab className={`react-tabs__tab ${classes.tabName}`}>
                participants
              </Tab>
              {isOwner && (
                <Tab className={`react-tabs__tab ${classes.tabName}`}>
                  requests
                </Tab>
              )}
            </TabList>
            <TabPanel>
              <Chat sendMessage={sendMessage} messages={messages} />
            </TabPanel>
            <TabPanel>
              {participants.map((participant, key) => (
                <ParticipantItem
                  key={key}
                  id={participant.id}
                  groupId={groupData.id}
                  groupName={`${groupData.speciality.label} ${groupData.type.label}`}
                  name={participant.name}
                  year={participant.year.label}
                  university={participant.university.label}
                  major={participant.major.label}
                  interests={participant.intreset}
                  email={participant.email}
                  phone={participant.phone}
                  telegram={participant.telegram}
                  // here "isOwner" refers to the owner of the group. Not the owner of the participantItem
                  isOwner={isOwner}
                  ownerId={groupData.owner}
                />
              ))}
            </TabPanel>
            {isOwner && (
              <TabPanel>
                {joinRequests.length > 0 &&
                  joinRequests.map((request, key) => (
                    <RequestItem
                      key={key}
                      userId={request.id}
                      groupId={groupData.id}
                      name={request.name}
                      year={request.year.label}
                      university={request.university.label}
                      major={request.major.label}
                      interests={request.intreset}
                      isExperienced={request.experience}
                      willingToLead={request.info.leader}
                      haveAnIdea={request.info.idea}
                      haveAnSupervisor={request.info.supervisor}
                      setJoinRequests={setJoinRequests}
                      groupName={`${groupData.speciality.label} ${groupData.type.label}`}
                    />
                  ))}
              </TabPanel>
            )}
          </Tabs>
        </div>
      </section>
    )
  );
};

export default GroupDetailsPage;
