import { defer } from "react-router-dom";

const GroupDetailsLoader =
  (sendRequest) =>
  ({ params: { id: groupId } }) => {
    return sendRequest("get", `/group/${groupId}`).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });
  };

const joinRequestsLoader =
  (sendRequest) =>
  ({ params: { id: groupId } }) => {
    return sendRequest("get", `/group/${groupId}/requests`).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });
  };

const participantsLoader =
  (sendRequest) =>
  ({ params: { id: groupId } }) => {
    return sendRequest("get", `/group/${groupId}/participants`).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });
  };

export const GroupDetailsPageLoader = (sendRequest) => async (args) => {
  const groupDetais = GroupDetailsLoader(sendRequest)(args);
  const joinRequests = joinRequestsLoader(sendRequest)(args);
  const participants = participantsLoader(sendRequest)(args);

  return defer({
    groupDetais,
    joinRequests,
    participants,
  });
};
