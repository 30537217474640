import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./GroupItem.module.css";
import PrimaryActionButton from "./PrimaryActionButton";
import { BeatLoader } from "react-spinners";
import useHttp from "../custom-hooks/useHttp";
import { toast } from "react-toastify";

const SuperAdminGroupDetailsItem = ({
  id,
  name,
  participantsCount,
  category,
  subCategory,
}) => {
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();
  const navigate = useNavigate();

  const deleteGroupAction = () => {
    let data = {
      groupId: id,
    };

    sendRequestAndTrackProgress("get", `/admin/group/${id}/delete`, data);
  };

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        toast.success("Group deleted successfully!");
        navigate("/admin");
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            "An error occurred. Please try again later."
        );
      }
    }
  }, [actionData]);

  return (
    <div className={`${classes.groupContainer}`}>
      <div className="row">
        <div className="col-lg col-12">
          <p className={classes.groupName}>{name}</p>
          <p className={`${classes.status} ${classes.accepted}`}>accepted</p>
          <p
            className={classes.metaData}
          >{`${participantsCount} Participants | ${category} | ${subCategory}`}</p>
        </div>
        <div className="col-lg-auto col-12 d-flex align-items-center">
          <div className="d-lg-block d-md-flex d-block justify-content-between w-100">
            <div>
              <Link
                to={`/group/${id}/${name.toLowerCase().split(" ").join("-")}`}
                className={classes.actionButtonLink}
              >
                <PrimaryActionButton
                  className={`${classes.actionButton}`}
                  buttonText="view"
                />
              </Link>
            </div>
            <div>
              <PrimaryActionButton
                className={`${classes.actionButton}`}
                buttonText={
                  isLoading ? (
                    <BeatLoader color="white" loading size={10} />
                  ) : (
                    "delete"
                  )
                }
                onClick={deleteGroupAction}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminGroupDetailsItem;
