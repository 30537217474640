import { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import FormikInputField from "../components/FormikInputField";
import FormikSelectInput from "../components/FormikSelectInput";
import PrimaryActionButton from "../components/PrimaryActionButton";
import classes from "./EditProfilePage.module.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  universities,
  years,
  genders,
  specialties as allInterests,
  majors,
} from "../data/constants";
import SelectInterestsField from "../components/SelectInterestsField";
import useHttp from "../custom-hooks/useHttp";
import FormikAutoCompleteSelectInput from "../components/FormikAutoCompleteSelectInput";

const EditProfilePage = () => {
  const loaderData = useLoaderData();
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();

  const navigate = useNavigate();

  const [currentInterests, setCurrentInterests] = useState([]);
  const [interestsError, setInterestsError] = useState(false);
  const [interestsErrorMessage, setInterestsErrorMessage] = useState("");
  const [interests, setInterests] = useState([]);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("");
  const [currentTelegram, setCurrentTelegram] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");

  const onSubmit = (values) => {
    console.log(interests.length);
    if (interests.length === 0) {
      setInterestsError(true);
      setInterestsErrorMessage("This field is required");
      return;
    }
    if (parseInt(interests.length) > 3) {
      setInterestsError(true);
      setInterestsErrorMessage("You can select only 3 interests at most");
      return;
    }

    const formValues = {
      name: values.name,
      gender: parseInt(values.gender),
      university: parseInt(values.university),
      year: parseInt(values.year),
      intreset: interests.map((interest) => interest.value),
      experience: JSON.parse(values.experience),
      isSocial: JSON.parse(values.isSocial),
      major: parseInt(values.major),
      telegram: values.telegram,
    };

    if (currentPhoneNumber !== values.phone) {
      formValues.phone = values.phone.toString();
    }

    if (currentEmail !== values.email) {
      formValues.email = values.email.toString();
    }

    if (values.password !== "") {
      formValues.password = values.password;
      formValues.oldPassword = values.oldPassword;
    }

    // hide all toast notifications
    toast.dismiss();

    sendRequestAndTrackProgress("post", "/profile/edit", formValues);
  };

  const [initialValues, setInitialValues] = useState({
    name: "",
    gender: "",
    email: "",
    phone: "",
    oldPassword: "",
    password: "",
    university: "",
    major: "",
    year: "",
    experience: "",
    telegram: "",
    isSocial: false,
  });

  // re-initialize the form values when the user data is available
  useEffect(() => {
    if (loaderData) {
      const { userData } = loaderData;

      userData
        .then(({ response }) => {
          const user = response.data.msg;

          const newInitialValues = {
            name: user.name || "",
            gender: user.gender.id || "",
            email: user.email || "",
            phone: user.phone || "",
            oldPassword: "",
            password: "",
            university: user.university.id || "",
            year: user.year.id || "",
            experience: user.experience,
            isSocial: user.isSocial,
            major: user.major.id || "",
            telegram: user.telegram || "",
          };

          const interests = user.intreset.map((interest) => interest.label);
          setCurrentInterests(interests);

          setCurrentPhoneNumber(user.phone || "");
          setCurrentTelegram(user.telegram || "");
          setCurrentEmail(user.email || "");
          setInitialValues(newInitialValues);
        })
        .catch(({ error }) => {
          toast.error(
            error?.response?.data?.msg || "Faild to fetch user data!"
          );
        });
    }
  }, [loaderData]);

  // set previosly selected interests
  useEffect(() => {
    if (currentInterests.length > 0 && allInterests.length > 0) {
      const selectedInterests = allInterests.filter((interest) =>
        currentInterests.includes(interest.key)
      );
      setInterests(selectedInterests);
    }
  }, [allInterests, currentInterests]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required").min(3),
    gender: Yup.string().required("This field is required"),
    email: Yup.string().required("This field is required"),
    phone: Yup.string()
      .matches(
        /^\+966\d+$/,
        "The phone number must start with +966 and contain only numbers after it."
      )
      .required("This field is required"),
    university: Yup.string().required("This field is required"),
    year: Yup.string().required("This field is required"),
    experience: Yup.string().required("This field is required"),
    major: Yup.string().required("This field is required"),
    isSocial: Yup.string().required("This field is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters"),
  });

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        toast.success(
          actionData.response?.data?.msg || "Profile updated successfully!"
        );
        navigate("/profile/edit");
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            "An error occurred. Please try again later."
        );
      }
    }
  }, [actionData]);

  return (
    <section className={classes.formContainer}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-9 col-12">
            <h1 className={classes.formTitle}>Edit Profile</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {(formik) => {
                return (
                  <Form>
                    <div className="row">
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikInputField
                          label="Name"
                          inputType="text"
                          fieldName="name"
                          required={true}
                        />
                      </div>
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikSelectInput
                          options={genders}
                          defaultValue="Select Gender"
                          name="gender"
                          label="Gender"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikInputField
                          label="Email"
                          inputType="email"
                          fieldName="email"
                          required={true}
                        />
                      </div>
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikInputField
                          label="Phone"
                          inputType="text"
                          fieldName="phone"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                    <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikInputField
                          label="Telegram"
                          inputType="text"
                          fieldName="telegram"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikInputField
                          label="Current Password"
                          inputType="password"
                          fieldName="oldPassword"
                        />
                      </div>
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikInputField
                          label="New Password"
                          inputType="password"
                          fieldName="password"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikSelectInput
                          options={universities.sort((a, b) =>
                            a.key.localeCompare(b.key)
                          )}
                          defaultValue="Select University"
                          name="university"
                          label="University"
                          required={true}
                        />
                      </div>
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikSelectInput
                          options={years}
                          defaultValue="Select Year"
                          name="year"
                          label="Year"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-12 ${classes.inputSpacer}`}>
                        <SelectInterestsField
                          allInterests={allInterests}
                          interests={interests}
                          interestsError={interestsError}
                          setInterestsError={setInterestsError}
                          interestsErrorMessage={interestsErrorMessage}
                          setInterestsErrorMessage={setInterestsErrorMessage}
                          setInterests={setInterests}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikSelectInput
                          options={majors.sort((a, b) =>
                            a.key.localeCompare(b.key)
                          )}
                          name="major"
                          label="Major"
                          setFieldValue={formik.setFieldValue}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikSelectInput
                          options={[
                            { value: true, key: "Yes" },
                            { value: false, key: "No" },
                          ]}
                          defaultValue="Select Value"
                          name="experience"
                          label="Have you ever published a paper?"
                          required={true}
                        />
                      </div>
                      <div
                        className={`d-none col-sm col-12 ${classes.inputSpacer}`}
                      >
                        <FormikSelectInput
                          options={[
                            { value: true, key: "Yes" },
                            { value: false, key: "No" },
                          ]}
                          defaultValue="Select Value"
                          name="isSocial"
                          label="Is Social"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-auto">
                        <PrimaryActionButton
                          type="button"
                          onClick={() => {
                            // set all the fields as touched. Otherwise formik will not validate the fields
                            formik.setTouched({
                              name: true,
                              gender: true,
                              email: true,
                              phone: true,
                              oldPassword: true,
                              password: true,
                              university: true,
                              year: true,
                              experience: true,
                              isSocial: true,
                            });

                            formik.validateForm().then((errors) => {
                              if (Object.keys(errors).length > 0) {
                                toast.error(
                                  "Please fix the errors before submitting!"
                                );
                                // scroll the page to the top
                                window.scrollTo(0, 0);
                              } else {
                                formik.handleSubmit();
                              }
                            });
                          }}
                          className={classes.submitBtn}
                          disabled={isLoading ? true : false}
                          style={{
                            cursor: isLoading ? "not-allowed" : "pointer",
                          }}
                          buttonText={
                            isLoading ? (
                              <BeatLoader color="white" loading size={10} />
                            ) : (
                              <p style={{ margin: "0" }}>Save</p>
                            )
                          }
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditProfilePage;
