import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import classes from "./FormikAutoCompleteSelectInput.module.css";
import FormikInputField from "./FormikInputField";
import { setIn, useField } from "formik";

const FormikAutoCompleteSelectInput = ({
  options,
  name,
  label,
  required,
  setFieldValue,
}) => {
  return (
    <div className={classes.autocompleteParent}>
      <FormikInputField
        label={label}
        fieldName={name}
        inputType="hidden"
        className={classes.hiddenFormInput}
        required={required}
      />
      <Autocomplete
        onChange={(event, newValue) => {
          setFieldValue(name, newValue?.value ? newValue.value : "");
        }}
        disablePortal
        id="combo-box-demo"
        options={options.map((option) => ({
          label: option.key,
          value: option.value,
        }))}
        renderInput={(params) => <TextField {...params} />}
        className={classes.autoCompleteInput}
      />
    </div>
  );
};

export default FormikAutoCompleteSelectInput;
