import { jwtDecode } from "jwt-decode";
import axios from "axios";

export default function useCheckIfAuthenticated() {
  const checkIfAuthenticated = (setAuth) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn")) || false;

    if (isLoggedIn && user) {
      // check if the user.AccessToken is expired and if it's expired,
      // send an API request to renew the token with user.RefreshToken
      const decodedToken = jwtDecode(user["accessToken"]);
      const current_time = Date.now().valueOf() / 1000;

      // If the token is not expired, is not expired, set setAuth to true
      if (decodedToken.exp > current_time) {
        setAuth(true, user);
      } else {
        getNewAccessToken("get", "/refresh-token", user, setAuth);
      }
    }
  };

  // this function is used to make api requests that need to
  // track the progress of request
  const getNewAccessToken = (method, url, user, setAuth) => {
    axios({
      method,
      url: process.env.REACT_APP_BACKEND_API_URL + url,
      headers: {
        Authorization: "Bearer " + user?.refreshToken,
      },
      data: {},
    })
      .then((response) => {
        setAuth(true, response.data);
      })
      .catch((error) => {
        setAuth(false);
      });
  };

  return { checkIfAuthenticated };
}
